import { Component } from '@angular/core';
import config from "devextreme/core/config";
import { environment } from 'src/environments/environment';
import { LoadingService } from './core/loading.service';
import * as localization from "devextreme/localization";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'wsh-admin';

    constructor(public loadingService: LoadingService) {
        (localization as any).disableIntl();
        config({
            thousandsSeparator: '.',
            decimalSeparator: ','
            // ...
        })
    }

    ngOnInit() {
        let currentVersion = localStorage.getItem('wsh_appVersion');
        if (currentVersion != environment.appVersion) {
            localStorage.setItem('wsh_appVersion', environment.appVersion);
            window.location.reload(true);
        }
    }
}
