import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatorService } from '../../core/translator.service';

@Component({
    selector: 'app-outside-layout',
    templateUrl: './outside-layout.component.html',
    styleUrls: ['./outside-layout.component.scss']
})
export class OutsideLayoutComponent implements OnInit {

    constructor(public router: Router, public translatorService: TranslatorService) { }

    ngOnInit(): void {
    }

}
