<div class="content-header">
    <span class="content-title"> {{ title | translate }} </span>
    <div class="content-breadcrumbs-container"> 
        <span class="content-breadcrumb">
            <a [routerLink]="['/']" routerLinkActive="router-link-active" class="breadcrumb-link"> {{ 'SHARED.HOME_PAGE' | translate }} </a>
        </span>
        <span *ngIf="first" class="content-breadcrumb">
            <i class="ti-angle-right"></i>
            <span *ngIf="!firstUrl"> {{ first | translate }} </span>
            <a *ngIf="firstUrl" [routerLink]="[firstUrl]" routerLinkActive="router-link-active" class="breadcrumb-link"> {{ first | translate }} </a>
        </span>
        <span *ngIf="second" class="content-breadcrumb">
            <i class="ti-angle-right"></i>
            <span *ngIf="!secondUrl"> {{ second | translate }} </span>
            <a *ngIf="secondUrl" [routerLink]="[secondUrl]" routerLinkActive="router-link-active" class="breadcrumb-link"> {{ second | translate }} </a>
        </span>
        <span *ngIf="id" class="content-breadcrumb">
            <i class="ti-angle-right"></i>
            <span> {{ id }} </span>
        </span>
    </div>
</div>