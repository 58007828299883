import { BrowserModule } from '@angular/platform-browser';
import { forwardRef, NgModule, Provider } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './outside-layout/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InsideLayoutModule } from './inside-layout/inside-layout.module';
import { NotFoundComponent } from './outside-layout/not-found/not-found.component';
import { RegistrationConfirmationComponent } from './outside-layout/registration-confirmation/registration-confirmation.component';
import { EmailChangeConfirmationComponent } from './outside-layout/email-change-confirmation/email-change-confirmation.component';
import { Routes } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { OutsideLayoutComponent } from './outside-layout/outside-layout/outside-layout.component';
import { OutsideGuard, InsideGuard, PendingChangesGuard } from './guards';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './http-error.interceptor';

export const API_INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: forwardRef(() => HttpErrorInterceptor),
    multi: true
  };


export const AppRoutes: Routes = [
    {
        path: 'change-email/:token/confirm',
        component: EmailChangeConfirmationComponent
    },
    {
        path: '',
        component: OutsideLayoutComponent,
        canActivate: [OutsideGuard],
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'registrations/confirm/:token',
                component: RegistrationConfirmationComponent
            }
        ]
    },
    {
        path: '',
        canActivate: [InsideGuard],
        loadChildren: () => import('./inside-layout/inside-layout.module').then(m => m.InsideLayoutModule)
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent,
        RegistrationConfirmationComponent,
        EmailChangeConfirmationComponent,
        OutsideLayoutComponent
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(
            AppRoutes,
            {
                onSameUrlNavigation: 'reload',
                preloadingStrategy: PreloadAllModules
            }
        ),
        BrowserAnimationsModule,
        BrowserAnimationsModule,
        FormsModule, 
        ReactiveFormsModule,
        InsideLayoutModule,
        HttpClientModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            isolate: false
        }),
        ToastrModule.forRoot({
            maxOpened: 5,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        })
    ],
    providers: [
        InsideGuard,
        OutsideGuard,
        PendingChangesGuard,
        HttpErrorInterceptor,
        API_INTERCEPTOR_PROVIDER
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }