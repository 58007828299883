import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private app: any;
    private layout: any;
    private user: any;

    constructor() {
        this.app = {
            owner: 'WSH',
            year: ((new Date()).getFullYear())
        };

        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

        this.user = {

        };
    }

    public getAppSetting(name: string): any {
        return name ? this.app[name] : this.app;
    }

    public getUserSetting(name: string): any {
        return name ? this.user[name] : this.user;
    }

    public getLayoutSetting(name: string): any {
        return name ? this.layout[name] : this.layout;
    }

    public setAppSetting(name: string, value: any): void {
        if (typeof this.app[name] !== 'undefined')
            this.app[name] = value;
    }

    public setUserSetting(name: string, value: any): void {
        if (typeof this.user[name] !== 'undefined')
            this.user[name] = value;
    }

    public setLayoutSetting(name: string, value: any): any {
        if (typeof this.layout[name] !== 'undefined')
            return this.layout[name] = value;
    }

    public toggleLayoutSetting(name: string): any {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }
}
