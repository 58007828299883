import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  private subject = new Subject<String>();

  constructor() {}

  setTitle(value: string) {
    this.subject.next(value);

  }

  onTitle(): Observable<any> {
    return this.subject.asObservable();
  }
}
