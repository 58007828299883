<ng-container *ngIf="dataSource">
    <dx-list *ngIf="selectedData != null && selectedData.length > 0"
        [items]="selectedData" 
        [displayExpr]="displayExpr" 
        selectionMode="none"
        [allowItemDeleting]="!readOnly" 
        [itemDeleteMode]="'static'"
        (onItemDeleted)="onItemDeleted($event)"
        [focusStateEnabled]="false"
        [activeStateEnabled]="false"
    >
        <div *dxTemplate="let item of 'item'">
            <div class="item-container" [title]="item[displayExpr]">
                <div>{{ item[displayExpr] }}</div>
            </div>
        </div>

    </dx-list>
    
    <dx-drop-down-box [placeholder]="'DEV-EXTREME.Select' | translate" *ngIf="!readOnly">
        <div *dxTemplate="let data of 'content'">
            <div class="selection-item-container clickable" [title]="'DEV-EXTREME.Select All'  | translate" (click)="selectAll()">
                <span class="mr-2 check-icon" [ngClass]="{ 'ti-check-box': allSelected, 'ti-control-stop': !allSelected }"></span>
                <div>{{ 'DEV-EXTREME.Select All' | translate }}</div>
            </div>
            <dx-list #itemsList
                [dataSource]="dataSource" 
                [searchEnabled]="true" 
                [displayExpr]="displayExpr" 
                [searchExpr]="displayExpr" 
                [height]="height"
                [searchTimeout]="searchTimeout"  
                [showSelectionControls]="false" 
                selectionMode="none"
                selectAllMode="page"
                applyValueMode="instantly"
                [activeStateEnabled]="false"
                [focusStateEnabled]="false"
                (onItemClick)="itemClick($event)"
            >
                <div *dxTemplate="let item of 'item'">
                    <div class="selection-item-container" [title]="item[displayExpr]">
                        <span class="mr-2 check-icon" [ngClass]="{ 'ti-check-box': isItemChecked(item), 'ti-control-stop': !isItemChecked(item) && !allSelected}"></span>
                        <div>{{ item[displayExpr] }}</div>
                    </div>
                </div>
            </dx-list>
        </div>
    </dx-drop-down-box>
</ng-container>