import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxTileViewModule } from 'devextreme-angular/ui/tile-view';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { DxNavBarModule } from 'devextreme-angular/ui/nav-bar';
import { DxMultiViewModule } from 'devextreme-angular/ui/multi-view';
import { DxHtmlEditorModule } from 'devextreme-angular/ui/html-editor';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxTreeListModule } from 'devextreme-angular/ui/tree-list';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { TagBoxComponent } from './tag-box/tag-box.component';
import { TranslateModule } from '@ngx-translate/core';
import { DxAutocompleteModule } from 'devextreme-angular/ui/autocomplete';
import { DxLoadIndicatorModule } from 'devextreme-angular';

const DX = [
  DxButtonModule,
  DxCheckBoxModule,
  DxChartModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxFileUploaderModule,
  DxFormModule,
  DxHtmlEditorModule,
  DxListModule,
  DxMultiViewModule,
  DxNavBarModule,
  DxNumberBoxModule,
  DxPieChartModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxSchedulerModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTileViewModule,
  DxToolbarModule,
  DxTooltipModule,
  DxTreeViewModule,
  DxTreeListModule,
  DxAutocompleteModule,
  DxLoadIndicatorModule
];

@NgModule({
  declarations: [
    TagBoxComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DX
  ],
  exports: [
    DX,
    TagBoxComponent
  ]
})
export class DxModule { }
