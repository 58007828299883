import { BaseModel } from "../base/base.models";

export class Notification extends BaseModel {
    read: boolean;
    userCreatedName: string;
    sendDate: string;
    relatedType: string;
    relatedEntity: string;
    // NotificationIn
    title: string;
    description: string;
    type: NotificationType;
    priority: NotificationPriority;
    recipientUsers: string;
    partners: string;
}

export enum NotificationType {
    SYSTEM,
    USER,
    PARTNER,
    SEND
}

export enum NotificationTypeCreate {
    USER,
    PARTNER,
}

export enum NotificationPriority {
    LOW, MEDIUM, HIGH
}
