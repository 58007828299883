<nav id="sidebar" #sidebar>
    <div class="sidebar-header">
        <!-- <img src="../../../../assets/logo.png" class="img-fluid height-30p d-block m-auto clickable" alt="" [routerLink]="['/']"> -->
    </div>
    <!-- <div class="close-icon-wrap shortAnim" (click)="toggleSidebarCollapse()">
        <i [ngClass]="sidebar.classList.contains('active') ? 'ti-menu menu-icon-hamburger' : 'ti-close'" class="clickable text-l"></i>
    </div> -->

    <dx-scroll-view [height]="scrollViewHeight" [scrollByThumb]="true">
        <ul class="list-unstyled pb-4 multi-level first-list item noselect">
            <!-- <span class="mb-4"></span> -->
            <p class="fs-12 text-center text-white d-none d-md-block">{{ today }}</p>
            <li *ngFor="let group of menuGroups" class="li-item shortAnim clickable outline-none">
                <input type="checkbox" id="{{group.translation}}">
                <label *ngIf="group.children" class="clickable category-link shortAnim fs-22 white-text {{group.translation}}" for="{{group.translation}}">
                    <i class="{{ group.icon }} mr-2 ml-2 category-icon"></i> <span class="category-text" data-cy="{{group.translation}}">{{group.translation | translate}}</span> <i class="ti-angle-down opacity50 shortAnim arrow-icon"></i>
                </label>
                <ul *ngIf="group.children" class="list-unstyled second-list white-text shortAnim">
                    <li *ngFor="let subItem of group.children; let i = index" class="li-item shortAnim">
                        <a data-cy="{{subItem.translation}}" *ngIf="!subItem.children" [ngClass]="{'white-text': selectedCategoryId == subItem.categoryId}" class="clickable category-link shortAnim"  [routerLink]="[subItem.link]" routerLinkActive="router-link-active" >
                            {{subItem.translation | translate}}
                        </a>
                        <input *ngIf="subItem.children" type="checkbox" id="{{subItem.translation}}">
                        <label *ngIf="subItem.children" class="clickable category-link second shortAnim fs-22 white-text {{subItem.translation}}" for="{{subItem.translation}}"> 
                            <!-- <i class="{{ subItem.icon }} mr-2 ml-2 category-icon"></i>  -->
                            <span data-cy="{{subItem.translation}}" class="category-text">{{subItem.translation | translate}}</span> <i class="ti-angle-down opacity50 shortAnim arrow-icon"></i>
                        </label>
                        <ul *ngIf="subItem.children" class="list-unstyled third-list white-text shortAnim">
                            <li *ngFor="let child of subItem.children; let i = index" class="li-item shortAnim">
                                <a data-cy="{{child.translation}}" [ngClass]="{'white-text': selectedCategoryId == child.categoryId}" class="clickable category-link third shortAnim"  [routerLink]="[child.link]" routerLinkActive="router-link-active" >
                                    {{child.translation | translate}}
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </dx-scroll-view>
</nav>