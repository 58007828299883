import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input('title') title: string;
  @Input('first') first: string;
  @Input('second') second?: string;
  @Input('id') id?: string;
  @Input('url') url?: string;

  public firstUrl: string;
  public secondUrl: string;

  constructor() { }

  ngOnInit(): void {
    if(this.url) {
      let urlParts = this.url.split('/');
      this.firstUrl = urlParts[1] ? `/${urlParts[1]}` : '';
      this.secondUrl = urlParts[2] ? `/${urlParts[2]}` : ''
    }
  }

}
