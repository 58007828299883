import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TermsPolicyCookiesService {
    public showTermsOfUse: boolean = false;
    public showPrivacyPolicy: boolean = false;
    public showCookiesConsent: boolean = true;

    constructor() { }
}
