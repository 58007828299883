<div class="modal-dialog modal-login">
    <div class="modal-content">
        <div class="modal-header">		
            <h5 class="modal-title">{{ 'SHARED.WELCOME' | translate }}</h5>
            <img class="img-fluid height-35p" src="../../../assets/eurobitLogoOnBlack.png">
        </div>
        
        <p class="login-subtitle" [@inOutAnimation] *ngIf="!showForgotPassword">Web shop admin</p>
        <div class="modal-body pt-0" *ngIf="!showForgotPassword">
            <form>
                <div class="form-group">
                    <i class="fa fa-user"></i>
                    <input data-cy="username" type="text" class="form-control" required [(ngModel)]="formData.username" name="username" [placeholder]="'SHARED.USERNAME' | translate">
                </div>
                <div class="form-group mt-1">
                    <i class="fa fa-lock"></i>
                    <input data-cy="password" type="password" class="form-control" required [(ngModel)]="formData.password" name="password" [placeholder]="'SHARED.PASSWORD' | translate">					
                </div>
                <div class="form-group mt-1">
                    <button data-cy="logInBtn" type="submit" class="btn btn-transparent btn-block btn-lg" (click)="login()">{{ 'SHARED.LOG_IN' | translate}}</button>
                </div>
            </form>
        </div>
        <div class="modal-footer" *ngIf="!showForgotPassword">
            <button class="btn btn-block forgot-password" (click)="showForgotPasswordForm()">{{ 'USERS.PASSWORD_FORGOT' | translate}}</button>
        </div>
            
        
        <p class="login-subtitle" [@inOutAnimation] *ngIf="showForgotPassword">{{  'USERS.PASSWORD_FORGOT' | translate }}</p>
        <div class="modal-body pt-0" *ngIf="showForgotPassword">
            <form>
                <div class="form-group">
                    <i class="fa fa-user"></i>
                    <input type="text" class="form-control" required [(ngModel)]="changePasswordFormData.email" name="email" [placeholder]="'SHARED.EMAIL' | translate">
                </div>
                <div class="form-group mt-1">
                    <button type="submit" class="btn btn-transparent btn-block btn-lg" (click)="changePassword()">{{ 'REGISTRATIONS.BUTTONS.APPROVE' | translate}}</button>
                </div>
            </form>
        </div>
        <div class="modal-footer" *ngIf="showForgotPassword">
            <button type="info" class="btn btn-block forgot-password" (click)="showForgotPasswordForm()">{{ 'SHARED.LOG_IN' | translate}}</button>
        </div>
    </div>
</div>