import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    public unfinishedRequests: boolean = false;

    private createdRequests: number = 0;
    private finishedRequests: number = 0;

    constructor() { }

    public showLoader(): void {
        this.createdRequests++;
        this.unfinishedRequests = true;
    }

    public hideLoader(): void {
        if (++this.finishedRequests == this.createdRequests)
            this.unfinishedRequests = false;
    }
}
