import { Route } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { LogoutComponent } from '../routes/logout/logout.component';
import { InsideGuard } from '../../guards';

export const routes: Route[] = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [InsideGuard],
        children: [
            {
                path: '', redirectTo: 'dashboard', pathMatch: 'full'
            },
            {
                path: 'logout', component: LogoutComponent, pathMatch: 'full'
            },
            {
                path: 'dashboard',
                loadChildren: () => import('../routes/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'users',
                data: {
                    authorities: ['R_USR']
                },
                loadChildren: () => import('../routes/users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'customers',
                data: {
                    authorities: ['R_USR']
                },
                loadChildren: () => import('../routes/customers/customers.module').then(m => m.CustomersModule)
            },
            {
                path: 'data-sync',
                data: {
                    authorities: ['DATA_SYNC']
                },
                loadChildren: () => import('../routes/data-sync/data-sync.module').then(m => m.DataSyncModule)
            },
            {
                path: 'partners-users',
                data: {
                    authorities: ['R_USR']
                },
                loadChildren: () => import('../routes/partners-users/partners-users.module').then(m => m.PartnersUsersModule)
            },
            {
                path: 'badges',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/badges/badges.module').then(m => m.BadgesModule)
            },
            {
                path: 'unit-of-measurements',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/unit-of-measurement/unit-of-measurement.module').then(m => m.UnitOfMeasurementModule)
            },
            {
                path: 'tax-rates',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/tax-rate/tax-rate.module').then(m => m.TaxRateModule)
            },
            {
                path: 'companies',
                data: {
                    authorities: ['R_GD']
                },
                loadChildren: () => import('../routes/companies/companies.module').then(m => m.CompaniesModule)
            },
            {
                path: 'currencies',
                data: {
                    authorities: ['R_GD']
                },
                loadChildren: () => import('../routes/currencies/currencies.module').then(m => m.CurrenciesModule)
            },
            {
                path: 'countries',
                data: {
                    authorities: ['R_GD']
                },
                loadChildren: () => import('../routes/countries/countries.module').then(m => m.CountriesModule)
            },
            {
                path: 'roles',
                data: {
                    authorities: ['R_RL']
                },
                loadChildren: () => import('../routes/roles/roles.module').then(m => m.RolesModule)
            },
            {
                path: 'municipalities',
                data: {
                    authorities: ['R_GD']
                },
                loadChildren: () => import('../routes/municipalities/municipalities.module').then(m => m.MunicipalitiesModule)
            },
            {
                path: 'payment-methods',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/payment-methods/payment-methods.module').then(m => m.PaymentMethodsModule)
            },
            {
                path: 'point-of-sales',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/points-of-sale/points-of-sale.module').then(m => m.PointsOfSaleModule)
            },
            {
                path: 'products',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/products/products.module').then(m => m.ProductsModule)
            },
            {
                path: 'offered-products',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/offered-products/offered-products.module').then(m => m.OfferedproductsModule)
            },
            {
                path: 'product-assortments',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/product-assortmets/product-assortmets.module').then(m => m.ProductAssortmentsModule)
            },
            {
                path: 'product-brands',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/product-brands/product-brands.module').then(m => m.ProductBrandsModule)
            },
            {
                path: 'product-categories',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/product-categories/product-categories.module').then(m => m.ProductCategoriesModule)
            },
            {
                path: 'product-groups',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/product-groups/product-groups.module').then(m => m.ProductGroupsModule)
            },
            {
                path: 'product-merchandise-groups',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/product-merchandise-groups/product-merchandise-groups.module').then(m => m.ProductMerchandiseGroupsModule)
            },
            {
                path: 'product-catalogues',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/product-catalogues/product-catalogues.module').then(m => m.ProductCataloguesModule)
            },
            {
                path: 'templates',
                data: {
                    authorities: ['R_CNF']
                },
                loadChildren: () => import('../routes/templates/templates.module').then(m => m.TemplatesModule)
            },
            {
                path: 'promotional-code-documents',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/promotional-code-documents/promotional-code-documents.module').then(m => m.PromotionalCodeDocumentsModule)
            },
            {
                path: 'cost-policies',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/cost-policies/cost-policies.module').then(m => m.CostPoliciesModule)
            },
            {
                path: 'rebate-policies',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/rebate-policies/rebate-policies.module').then(m => m.RebatePoliciesModule)
            },
            {
                path: 'gift-packages',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/gift-package/gift-package.module').then(m => m.GiftPackageModule)
            },
            {
                path: 'means-of-delivery',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/means-of-delivery/means-of-delivery.module').then(m => m.MeansOfDeliveryModule)
            },
            {
                path: 'delivery-zones',
                data: {
                    authorities: ['R_GD']
                },
                loadChildren: () => import('../routes/delivery-zones/delivery-zones.module').then(m => m.DeliveryZonesModule)
            },
            {
                path: 'distribution-channels',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/distribution-channels/distribution-channels.module').then(m => m.DistributionChannelsModule)
            },
            {
                path: 'distribution-calendars',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/distribution-calendars/distribution-calendars.module').then(m => m.DistributionCalendarsModule)
            },
            {
                path: 'customer-orders',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('./customer-orders/customer-orders.module').then(m => m.CustomerOrdersModule)
            },
            {
                path: 'b2b-orders',
                data: {
                    authorities: ['R_B2B_ORDR']
                },
                loadChildren: () => import('./b2b-orders/b2b-orders.module').then(m => m.B2bOrdersModule)
            },
            {
                path: 'default-email-templates',
                data: {
                    authorities: ['CUD_GD']
                },
                loadChildren: () => import('../routes/default-email-templates/default-email-templates.module').then(m => m.DefaultEmailTemplatesModule)
            },
            {
                path: 'banners',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('./banners/banners.module').then(m => m.BannersModule)
            },
            {
                path: 'news',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('./news/news.module').then(m => m.NewsModule)
            },
            {
                path: 'exception-logs',
                data: {
                    authorities: ['EXC_LOG']
                },
                loadChildren: () => import('./exception-logs/exception-logs.module').then(m => m.ExceptionLogsModule)
            },
            {
                path: 'business-partners',
                data: {
                    authorities: ['R_PRTNR']
                },
                loadChildren: () => import('./business-partners/business-partners.module').then(m => m.BusinessPartnerModule)
            },
            {
                path: 'registration-requests',
                data: {
                    authorities: ['RA_RGST_RQST']
                },
                loadChildren: () => import('./registration-requests/registration-requests.module').then(m => m.RegistrationRequestsModule)
            },
            {
                path: 'shop-dashboard-configuration',
                data: {
                    authorities: ['R_DSHB_CNFG']
                },
                loadChildren: () => import('./shop-dashboard-configuration/shop-dashboard-configuration.module').then(m => m.ShopDashboardConfigurationModule)
            },
            {
                path: 'third-party-auth',
                data: {
                    authorities: ['CRUD_3RD_PRTY_AUTH']
                },
                loadChildren: () => import('../routes/third-party-api-auth/third-party-api-auth.module').then(m => m.ThirdPartyApiAuthModule)
            },
            {
                path: 'document-numbering',
                data: {
                    authorities: ['R_CD']
                },
                loadChildren: () => import('../routes/document-numbering/document-numbering.module').then(m => m.DocumentNumberingModule)
            },
            {
                path: 'profile',
                loadChildren: () => import('../routes/profile/profile.module').then(m => m.ProfileModule)
            }
        ]
    }
];
