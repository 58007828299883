import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(private translateService: TranslateService, private toastr: ToastrService) { }
    public success(message: string, time: number = null): void {
        if (time == null) {
            if(message.length < 100)
                time = 3000;
            else
                time = message.length * 40;
        }
        this.toastr.success(this.translateService.instant(message), '', {
            timeOut: time
        });
    }
    public error(message: any, json: boolean = false, time: number = null): void {
        let errorData = message;
        let detail = '';
        if(message && message.error && message.error.error_description) {
            detail = message.error.error_description
        } else {
            detail = (json ? JSON.parse(message._body).error_description : message).toString();
        }

        if (time == null) {
            if(detail.length < 60)
                time = 8000;
            else
                time = 20000;
        }

        this.toastr.error(this.translateService.instant(detail), '', {
            timeOut: time
        });
    }
    public successCustomMessage(entireMessage: string, time: number = null): void {
        if (time == null) {
            if(entireMessage.length < 100)
                time = 3000;
            else
                time = entireMessage.length * 40;
        }
        this.toastr.success(entireMessage, '', {
            timeOut: time
        });
    }
}