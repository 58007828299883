
import { AuthService } from '../../core/auth.service';
import { AlertService } from '../../core/alert.service';

export class BaseModel {
    id?: string;
    dateCreated?: Date;
    dateModified?: Date;
    version?: number = 0;
    userCreated?: string;
    userModified?: string;
    breadcrumbsId?: string;
    companyId?: string;
}

export class BaseModelActive extends BaseModel {
    active: boolean = true;
}

export class ValueLabel {
    value: any;
    label: any;
}

export class IdLabel {
    id: any;
    label: any;
  
    constructor(id: any, label: any){
        this.id = id;
        this.label = label;
    }
}

export enum BooleanFields {
    true, false
}

export class RouteAuthorities {
    public constructor(
        private authService: AuthService,
        private alertService: AlertService,
        public c: string | string[],
        public r: string | string[],
        public u: string | string[],
        public d: string | string[]
    ) {
        this.init(c, r, u, d);
    }
    
    private _create: string[] = null;
    
    public get create(): boolean {
        return this.check('c');
    }
    
    public get createToast(): boolean {
        return this.check('c', true);
    }
    
    private _read: string[] = null;
    
    public get read(): boolean {
        return this.check('r');
    }
    
    public get readToast(): boolean {
        return this.check('r', true);
    }
    
    private _update: string[] = null;
    
    public get update(): boolean {
        return this.check('u');
    }
    
    public get updateToast(): boolean {
        return this.check('u', true);
    }
    
    private _delete: string[] = null;
    
    public get delete(): boolean {
        return this.check('d');
    }
    
    public get deleteToast(): boolean {
        return this.check('d', true);
    }

    private _canCreate: boolean = false;
    private _canRead: boolean = false;
    private _canUpdate: boolean = false;
    private _canDelete: boolean = false;

    public get canCreate(): boolean {
        return this._canCreate;
    }

    public get canRead(): boolean {
        return this._canRead;
    }

    public get canUpdate(): boolean {
        return this._canUpdate;
    }

    public get canDelete(): boolean {
        return this._canDelete;
    }
    
    public init(c: string | string[], r: string | string[], u: string | string[], d: string | string[]): RouteAuthorities {
        if (c)
            Array.isArray(c) ? this._create = c : this._create = [<string>c];
        if (r)
            Array.isArray(r) ? this._read = r : this._read = [<string>r];
        if (u)
            Array.isArray(u) ? this._update = u : this._update = [<string>u];
        if (d)
            Array.isArray(d) ? this._delete = d : this._delete = [<string>d];
        
        return this;
    }
    
    private check(what: 'c' | 'r' | 'u' | 'd', show: boolean = false): boolean {
        let can: boolean = false;

        switch (what) {
            case 'c':
                can = !!this._create && this.authService.hasAnyAuthority(...this._create);
                break;
            case 'r':
                can = !!this._read && this.authService.hasAnyAuthority(...this._read);
                break;
            case 'u':
                can = !!this._update && this.authService.hasAnyAuthority(...this._update);
                break;
            case 'd':
                can = !!this._delete && this.authService.hasAnyAuthority(...this._delete);
                break;
        }
        
        if (show && !can)
            this.alertService.error('ERRORS.UNAUTHORIZED');
        
        return can;
    }
}

export enum DayOfWeek {
    MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY 
}

export class MenuItem {
    key?: string;
    disabled?: boolean = false;
    visible?: boolean = false;
    roles?: string[];
    authorities?: string[];
    items?: MenuItem[];
}

export class Image extends BaseModel {
    isMain: boolean;
    path: string;
}

/**
 * Sources for lookup
 */

export enum LookupSource {
    MUNICIPALITY = 'municipality',
    PRODUCT_CATALOGUE = "product_catalogue",
    POINT_OF_SALE = "point_of_sale",
    PAYMENT_METHOD = "payment_method",
    MEANS_OF_DELIVERY = "means_of_delivery",
    COST_POLICY = "cost_policy",
    BANNERS = "banner",
    NEWS = "news",
    PRODUCT_MERCHANDISE = "product_merchandise_group",
    PRODUCTS = "product",
    PRODUCT_TEMPLATE_ATTRIBUTE = "product_template_attribute"
}
/**
 * Fields for request fields and search fields
 * 
 * fields that includes '_' will be modified to pascal case in baseService
 * Example valid_from -> validFrom
 *  
 */
export enum FieldsType {
    N = "name",
    D = "description",
    C = "code",
    V = "valid_from",
    L = "login",
    E = "email",
    v = "value",
    f = "value_from",
    o = "value_to",
    F = "order_value_from",
    O = "order_value_to",
    T = "type",
    t = "title",
    l = "link",
    I = "image",
    B = "button_text",
    U = "unit_of_measurement"
}
/**
 * Lookup service - generate string with starting letter 
 * 
 * This service can be used for generate list of fields for lookup.
 * Input of method "get" accept only first letter of FieldsType key (case sensitive).
 * Example to get list of: [name,description,code] 'fields' param have to be: LS.get('NDC')
 */
export class LS {
    static get(fields: string): FieldsType[] {
        return Object.keys(FieldsType)
            .filter(v => fields.includes(v))
            .map(v => FieldsType[v])
    }
}

export enum AppClientType {
    WEB_SHOP, ADMIN, B2B_SHOP
}