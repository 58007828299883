import { Injectable, } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParameterCodec } from '@angular/common/http';
import { UserType } from '../inside-layout/routes/users/users.models';
import { environment } from 'src/environments/environment';

export class Token {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    issued: Date;
    user_name: string;
    userId: string;
    roles: string[];
    authorities: string[];
    userExpireDate: Date;
    credentialsExpireDate: Date;
    language: string;
    accessPolicy?: any;
    email: string;
    companyId: string;
    userType: string | UserType;
}
@Injectable({
    providedIn: "root"
})
export class AuthService {
    private readonly endpoint: string = `wsh/oauth/token`;
    private readonly tokenName: string = 'wsh_token';
    private readonly versionStorageName: string = 'wsh_appVersion';

    private readonly credentialsExpiryDateStorageName: string = 'last_shown_credentials_expiry_date_message';
    public showCredentialsExpiryDateMessage: boolean = false;
    public credentialsExpiryDaysLeft: number;

    private _loginHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    private _authHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    private _anonymousHeaders: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    public onLangChange: (tokenLanguage: string) => void = null;

    constructor(private http: HttpClient, private router: Router) {
        if (this.isTokenInStorage) {
            this.setToken(JSON.parse(localStorage.getItem(this.tokenName)));
            // this.token = JSON.parse(localStorage.getItem(this.tokenName));
            // this._authHeaders.delete('Authorization');
            // this._authHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.token.access_token });
        }
        this.checkCredentialsExpiryDate();

        setInterval(() => {
            if (!this.isTokenInStorage && this.isTokenSet)
                this.router.navigate(['logout']);
        }, 3000);

        setInterval(() => {
            this.checkCredentialsExpiryDate();
        }, 1800000);
    }

    public get anonymousHeaders(): HttpHeaders {
        return this._anonymousHeaders;
    }

    public get authHeaders(): HttpHeaders {
        return this._authHeaders;
    }

    public get isTokenSet(): boolean {
        return this.token != null;
    }

    public get isTokenInStorage(): boolean {
        return !!localStorage.getItem(this.tokenName);
    }

    public get username(): string {
        return this.token != null ? this.token.user_name : '';
    }

    public get userType(): string {
        return this.token != null ? <string>this.token.userType : '';
    }

    public get userId(): string {
        return this.token != null ? this.token.userId : '';
    }

    public get companyId(): string {
        return this.token != null ? this.token.companyId : '';
    }

    public get roles(): string[] {
        return this.token != null ? this.token.roles : [];
    }

    public get authorities(): string[] {
        return this.token != null ? this.token.authorities : [];
    }

    public get language(): string {
        return this.token != null ? this.token.language : '';
    }

    public get refreshTokenValue(): string {
        return this.token != null ? this.token.refresh_token : null;
    }

    public token: Token;

    private setToken(token: Token) {

        token.issued = new Date();

        let userInfo: Token = JSON.parse(atob(token.access_token.split('.')[1]));

        token.roles = userInfo.roles;
        token.authorities = userInfo.authorities;
        token.user_name = userInfo.user_name;

        this.token = token;

        localStorage.removeItem(this.tokenName);
        localStorage.setItem(this.tokenName, JSON.stringify(token));

        this._authHeaders.delete('Authorization');
        this._authHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.token.access_token });

        this.checkCredentialsExpiryDate();
    }

    public login(username: string, password: string): Observable<any> {
        // username = username.trim();
        // let parameters: string = `username=${username}&password=${password}&grant_type=password`;
        // return this.http
        //     .post(this.endpoint, parameters, { headers: this._loginHeaders })
        //     .pipe(map((response: any) => {
        //         this.setToken(response);
        //     }));


        let clientId: string = this.determineCompanyID().client;
        let clientSecret: string = this.determineCompanyID().secret;
        let body: string = `client_id=${clientId}&client_secret=${clientSecret}&grant_type=password&username=${standardEncoding(username.trim())}&password=${standardEncoding(password.trim())}`;
        return this.http.post(this.endpoint, body, { headers: this._loginHeaders }).pipe(map((response: any) => this.setToken(response)));
    }

    public refreshTokenIfNeeded(): Observable<void> {
        if (this.token != null) {
            let expirationTime: Date = new Date();
            let currentTime: Date = new Date();
            expirationTime.setTime(new Date(this.token.issued).getTime() + (this.token.expires_in - 30) * 1000);
            if (currentTime.getTime() > expirationTime.getTime())
                return this.refreshToken();
        }
        return of(null).pipe(map(() => null));
    }

    public hasRole(role: string): boolean {
        return this.token != null && this.token.roles && this.token.roles.includes(role);
    }

    public hasAnyRole(...roles: string[]): boolean {
        return roles == null || (this.token != null && this.token.roles && roles.some(role => this.hasRole(role)));
    }

    public hasAuthority(authority: string): boolean {
        return this.token != null && this.token.authorities && this.token.authorities.includes(authority);
    }

    public hasAnyAuthority(...authorities: string[]): boolean {
        return authorities == null || (this.token != null && this.token.authorities && authorities.some(authority => this.hasAuthority(authority)));
    }

    public performRedirection(): void {
        this.router.navigate(['']);
    }

    public setLanguageHeaders(language: string): void {
        this._loginHeaders.delete('Accept-Language');
        this._loginHeaders.append('Accept-Language', language);
        this._anonymousHeaders = this._anonymousHeaders.delete('Accept-Language');
        this._anonymousHeaders = this._anonymousHeaders.append('Accept-Language', language);
        this._authHeaders.delete('Accept-Language');
        this._authHeaders.append('Accept-Language', language);
    }

    /**
     * Deletes auth token from local storage.
     */
    public removeAuth(): void {
        delete this.token;
        this._authHeaders.delete('Authorization');
        let currentVersion = localStorage.getItem(this.versionStorageName);
        let cookies = localStorage.getItem('cookies_accepted');
        localStorage.clear();
        if (currentVersion != null)
            localStorage.setItem(this.versionStorageName, currentVersion);
        if (cookies)
            localStorage.setItem('cookies_accepted', cookies);
    }

    public refreshToken(): Observable<void> {
        if (!this.refreshTokenValue)
            this.removeAuth();
        const parameters: string = `refresh_token=${this.refreshTokenValue}&grant_type=refresh_token`;
        return this.http
            .post(this.endpoint, parameters, { headers: this._loginHeaders })
            .pipe(
                map((response: any) => this.setToken(response)),
                catchError((err, caught) => {
                    if (err.status == 401) {
                        if (err.error && err.error.error == 'invalid_token') {
                            this.removeAuth();
                        }
                    }
                    return EMPTY;
                })
            );
    }

    public checkCredentialsExpiryDate() {
        if (this.isTokenSet && this.token.credentialsExpireDate) {
            let t2 = new Date(`${this.token.credentialsExpireDate}Z`).getTime();
            let t1 = new Date().getTime();

            this.credentialsExpiryDaysLeft = parseInt(((t2 - t1) / (24 * 3600 * 1000)).toString());

            if (this.credentialsExpiryDaysLeft < 10) {
                if (!!localStorage.getItem(this.credentialsExpiryDateStorageName)) {
                    let d1 = new Date(localStorage.getItem(this.credentialsExpiryDateStorageName));
                    let d2 = new Date();

                    if (d1.toDateString() !== d2.toDateString()) {
                        this.showCredentialsExpiryDateMessage = true;
                    }
                } else
                    this.showCredentialsExpiryDateMessage = true;
            }
        }
    }

    public setShowCredentialsExpiryDateMessage() {
        this.showCredentialsExpiryDateMessage = false;
        localStorage.removeItem(this.credentialsExpiryDateStorageName);
        localStorage.setItem(this.credentialsExpiryDateStorageName, new Date().toString());
    }

    determineCompanyID() {
        switch (window.location.hostname.split('.')[0]) {
            case 'domod':
                return {
                    companyId: environment.domodId,
                    client: environment.client.wsh,
                    secret: environment.secret.wsh
                };
            case 'amko':
            case 'admin':
                return {
                    companyId: environment.amkoId,
                    client: environment.client.wsh,
                    secret: environment.secret.wsh
                };
            case 'exclusive':
            default:
                return {
                    companyId: environment.exclusiveId,
                    client: environment.client.exc,
                    secret: environment.secret.exc
                };
        }
    }
}

export class HttpUrlEncodingCodec implements HttpParameterCodec {
    encodeKey(k: string): string { return standardEncoding(k); }
    encodeValue(v: string): string { return standardEncoding(v); }
    decodeKey(k: string): string { return decodeURIComponent(k); }
    decodeValue(v: string) { return decodeURIComponent(v); }
}

function standardEncoding(v: string): string {
    return encodeURIComponent(v);
}

