import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { DxListComponent } from 'devextreme-angular';
import { BaseService } from '../base/base.service';

@Component({
    selector: 'app-tag-box',
    templateUrl: './tag-box.component.html',
    styleUrls: ['./tag-box.component.scss']
})
export class TagBoxComponent implements OnInit {
    @ViewChild('itemsList', { static: false }) itemsList: DxListComponent;
    @Input('url') url: string;
    @Input('displayExpr') displayExpr: string;
    @Input('valueExpr') valueExpr: string;
    @Input('selectedData') selectedData: any;
    @Input('dataSource') dataSource: any;
    @Input('height') height: number = 400;
    @Input('readOnly') readOnly: boolean = false;
    @Input('searchTimeout') searchTimeout: number = 500;
    @Output() onSelectionChangedEvent = new EventEmitter();
    @Output() onItemDeletedEvent = new EventEmitter();
    allSelected: boolean = false;

    constructor(private baseService: BaseService) { }

    ngOnInit() {
        this.onSelectionChangedEvent.emit(this.selectedData);
    }

    ngOnChanges(changes: SimpleChange) {
    }

    itemClick(e){
        if(this.selectedData == null) {
            this.selectedData = [];
        }

        if(e.itemData) {
            let index = this.selectedData.findIndex(sd => sd.id == e.itemData.id);
            
            if(index < 0)
                this.selectedData.push(e.itemData)
            else {
                this.selectedData = this.selectedData.filter(sd => sd.id != e.itemData.id)
                this.allSelected = false;
            }
        }
        this.selectedData.forEach(element => {
            this.selectedData.filter(element2 => element2.id != element.id)
        });

        this.onSelectionChangedEvent.emit(this.selectedData)
    }

    onItemDeleted(event) {
        if(this.selectedData) {
            let index = this.selectedData.findIndex(wc => wc.id == event.itemData.id)
            if(index > -1) {
                this.selectedData = this.selectedData.filter(wc => wc.id != event.itemData.id);
                this.allSelected = false;
            }
        }
        this.onSelectionChangedEvent.emit(this.selectedData);
    }

    isItemChecked(item) {
        if(this.selectedData)
            return this.selectedData.findIndex(data => data.id == item.id) > -1;
        return false;
    }

    selectAll() {
        this.allSelected = !this.allSelected;

        if(this.allSelected) {
            this.baseService.getList(this.url, null, null, (response) => {
                this.selectedData = response;
                this.onSelectionChangedEvent.emit(this.selectedData);
            });
        } else {
            this.selectedData = [];
            this.onSelectionChangedEvent.emit(this.selectedData);
        }

    }
}
