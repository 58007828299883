import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from '../core/core.module';
import { TermsPolicyCookiesComponent } from './terms-policy-cookies/terms-policy-cookies.component';
import { TermsPolicyCookiesService } from './terms-policy-cookies/terms-policy-cookies.service';
import { BaseService } from './base/base.service';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { ProductSelectionComponent } from './product-selection/product-selection.component';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
@NgModule({
    declarations: [
        TermsPolicyCookiesComponent,
        BreadcrumbsComponent,
        ProductSelectionComponent,
        SafeHtmlPipe
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            extend: true,
            isolate: false
        }),
        CoreModule,
        RouterModule
    ],
    providers: [
        TermsPolicyCookiesService,
        BaseService
    ],
    exports: [
        CommonModule,
        CoreModule,
        TranslateModule,
        TermsPolicyCookiesComponent,
        BreadcrumbsComponent,
        SafeHtmlPipe
    ]
})
export class SharedModule { }
