import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../core/breadcrumbs.service'
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    sidebarOpen: boolean = true;
    title: string;
    subscription: Subscription;

    constructor(public breadcrumbsService: BreadcrumbsService, public router: Router) {
    }

    ngOnInit() {
        this.subscription = this.breadcrumbsService.onTitle().subscribe(value => {
            this.title = value;
        });
    }

    ngAfterViewInit() {
    }

    sidebarToggled(event) {
        this.sidebarOpen = !event;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
