import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { routes } from './routes/routes';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material.module';
import { FooterComponent } from './layout/footer/footer.component';
import { LogoutComponent } from './routes/logout/logout.component';
import { DxModule } from '../shared/dx.module';

@NgModule({
  declarations: [LayoutComponent, HeaderComponent, SidebarComponent, FooterComponent, LogoutComponent],
  imports: [
    SharedModule,
    MaterialModule,
    DxModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    LayoutComponent, HeaderComponent, SidebarComponent, LogoutComponent
  ]
})
export class InsideLayoutModule { }
