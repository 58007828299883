const currencies = {
    link: '/currencies',
    icon: 'ti-world',
    translation: 'MENU.ADMINISTRATION.CURRENCIES',
    authorities: ['R_GD']
};

const countries = {
    link: '/countries',
    icon: 'ti-shopping-cart-full',
    translation: 'MENU.DATA_MANAGEMENT.COUNTRIES',
    authorities: ['R_GD']
};

const municipalities = {
    link: '/municipalities',
    icon: 'ti-briefcase',
    translation: 'MENU.DATA_MANAGEMENT.MUNICIPALITIES',
    authorities: ['R_GD']
};

const companies = {
    link: '/companies',
    icon: 'ti-briefcase',
    translation: 'MENU.ADMINISTRATION.COMPANIES',
    authorities: ['R_GD']
};

const defaultEmailTemplates = {
    link: '/default-email-templates',
    icon: 'ti-email',
    translation: 'MENU.SETTINGS.DEFAULT_EMAIL_TEMPLATES',
    authorities: ['CUD_GD']
};

const pointsOfSale = {
    link: '/point-of-sales',
    icon: 'ti-location-pin',
    translation: 'MENU.ADMINISTRATION.POINTS_OF_SALE',
    tooltip: 'MENU.ADMINISTRATION.POINTS_OF_SALE',
    authorities: ['R_CD']
};

const products = {
    link: '/products',
    icon: 'ti-mouse-alt',
    translation: 'MENU.PRODUCTS.CODES',
    tooltip: 'MENU.PRODUCTS.CODES',
    authorities: ['R_CD']
};

const offeredProducts = {
    link: '/offered-products',
    icon: 'ti-mouse-alt',
    translation: 'MENU.PRODUCTS.OFFERED_PRODUCTS',
    tooltip: 'MENU.PRODUCTS.OFFERED_PRODUCTS',
    authorities: ['R_CD']
};

const productCatalogues = {
    link: '/product-catalogues',
    icon: 'ti-mouse-alt',
    translation: 'MENU.PRODUCTS.CATALOGUES',
    tooltip: 'MENU.PRODUCTS.CATALOGUES',
    authorities: ['R_CD']
};

const users = {
    link: '/users',
    icon: 'ti-user',
    translation: 'MENU.ADMINISTRATION.USERS',
    tooltip: 'MENU.ADMINISTRATION.USERS',
    authorities: ['R_USR']
};

const customers = {
    link: '/customers',
    icon: 'ti-customers',
    translation: 'MENU.ADMINISTRATION.CUSTOMERS',
    tooltip: 'MENU.ADMINISTRATION.CUSTOMERS',
    authorities: ['R_USR']
};

const partnerUsers = {
    link: '/partners-users',
    icon: 'ti-customers',
    translation: 'MENU.ADMINISTRATION.PARTNERS_USERS',
    tooltip: 'MENU.ADMINISTRATION.PARTNERS_USERS',
    authorities: ['R_USR']
};

const productAssortments = {
    link: '/product-assortments',
    icon: 'ti-layers-alt',
    translation: 'MENU.PRODUCTS.PRODUCT_ASSORTMENTS',
    authorities: ['R_CD']
};

const productBrands = {
    link: '/product-brands',
    icon: 'ti-layers-alt',
    translation: 'MENU.PRODUCTS.PRODUCT_BRANDS',
    authorities: ['R_CD']
};

const productCategories = {
    link: '/product-categories',
    icon: 'ti-layers-alt',
    translation: 'MENU.PRODUCTS.PRODUCT_CATEGORIES',
    authorities: ['R_CD']
};

const productGroups = {
    link: '/product-groups',
    icon: 'ti-layers-alt',
    translation: 'MENU.PRODUCTS.PRODUCT_GROUPS',
    authorities: ['R_CD']
};

const productMerchandiseGroups = {
    link: '/product-merchandise-groups',
    icon: 'ti-layers-alt',
    translation: 'MENU.PRODUCTS.PRODUCT_MERCHANDISE_GROUPS',
    authorities: ['R_CD']
};

const badges = {
    link: '/badges',
    icon: 'ti-crown',
    translation: 'MENU.ADMINISTRATION.BADGES',
    authorities: ['R_CD']
};

const unitOfMeasurement = {
    link: '/unit-of-measurements',
    icon: 'ti-crown',
    translation: 'MENU.ADMINISTRATION.UNIT_OF_MEASUREMENTS',
    authorities: ['R_GD']
};

const paymentMethods = {
    link: '/payment-methods',
    icon: 'ti-credit-card',
    translation: 'MENU.ADMINISTRATION.PAYMENT_METHODS',
    tooltip: 'MENU.ADMINISTRATION.PAYMENT_METHODS',
    authorities: ['R_CD']
};

const documentNumbering = {
    link: '/document-numbering',
    icon: 'ti-view-list-alt',
    translation: 'MENU.ADMINISTRATION.DOCUMENT_NUMBERING',
    tooltip: 'MENU.ADMINISTRATION.DOCUMENT_NUMBERING',
    authorities: ['R_CD']
};

const roles = {
    link: '/roles',
    icon: 'ti-lock',
    translation: 'MENU.ADMINISTRATION.ROLES',
    tooltip: 'MENU.ADMINISTRATION.ROLES',
    authorities: ['R_RL']
};

const templates = {
    link: '/templates',
    icon: 'ti-write',
    translation: 'MENU.DATA_MANAGEMENT.TEMPLATES',
    tooltip: 'MENU.DATA_MANAGEMENT.TEMPLATES',
    authorities: ['R_CNF']
};

const deliveryZones = {
    link: '/delivery-zones',
    icon: 'ti-pin',
    translation: 'MENU.ADMINISTRATION.DELIVERY_ZONES',
    tooltip: 'MENU.ADMINISTRATION.DELIVERY_ZONES',
    authorities: ['R_GD']
};

const distributionChannels = {
    link: '/distribution-channels',
    icon: 'ti-control-shuffle',
    translation: 'MENU.ADMINISTRATION.DISTRIBUTION_CHANNELS',
    tooltip: 'MENU.ADMINISTRATION.DISTRIBUTION_CHANNELS',
    authorities: ['R_CD']
};

const distributionCalendars = {
    link: '/distribution-calendars',
    icon: 'ti-calendar',
    translation: 'MENU.ADMINISTRATION.DISTRIBUTION_CALENDARS',
    tooltip: 'MENU.ADMINISTRATION.DISTRIBUTION_CALENDARS',
    authorities: ['R_CD']
};

const promotionalCodeDocuments = {
    link: '/promotional-code-documents',
    icon: 'ti-bookmark',
    translation: 'MENU.DATA_MANAGEMENT.PROMOTIONAL_CODE_DOCUMENTS',
    tooltip: 'MENU.DATA_MANAGEMENT.PROMOTIONAL_CODE_DOCUMENTS',
    authorities: ['R_CD']
};

const rebatePolicies = {
    link: '/rebate-policies',
    icon: 'ti-bar-chart-alt',
    translation: 'MENU.ADMINISTRATION.REBATE_POLICIES',
    tooltip: 'MENU.ADMINISTRATION.REBATE_POLICIES',
    authorities: ['R_CD']
};

const costPolicies = {
    link: '/cost-policies',
    icon: 'ti-money',
    translation: 'MENU.ADMINISTRATION.COST_POLICIES',
    tooltip: 'MENU.ADMINISTRATION.COST_POLICIES',
    authorities: ['R_CD']
};

const giftPackages = {
    link: '/gift-packages',
    icon: 'ti-gift',
    translation: 'MENU.ADMINISTRATION.GIFT_PACKAGES',
    tooltip: 'MENU.ADMINISTRATION.GIFT_PACKAGES',
    authorities: ['R_CD']
};

const meansOfDelivery = {
    link: '/means-of-delivery',
    icon: 'ti-package',
    translation: 'MENU.ADMINISTRATION.MEANS_OF_DELIVERY',
    tooltip: 'MENU.ADMINISTRATION.MEANS_OF_DELIVERY',
    authorities: ['R_CD']
};

const taxRates = {
    link: '/tax-rates',
    icon: 'ti-package',
    translation: 'MENU.ADMINISTRATION.TAX_RATE',
    tooltip: 'MENU.ADMINISTRATION.TAX_RATE',
    authorities: ['R_CD']
};

const b2bOrders = {
    link: '/b2b-orders',
    icon: 'ti-shopping-cart-full',
    translation: 'MENU.ADMINISTRATION.B2B_ORDERS',
    tooltip: 'MENU.ADMINISTRATION.B2B_ORDERS',
    authorities: ['R_B2B_ORDR']
};

const customerOrders = {
    link: '/customer-orders',
    icon: 'ti-shopping-cart',
    translation: 'MENU.ADMINISTRATION.CUSTOMER_ORDERS',
    tooltip: 'MENU.ADMINISTRATION.CUSTOMER_ORDERS',
    authorities: ['R_ORD']
};

const dataSync = {
    link: '/data-sync',
    icon: 'ti-cloud-up',
    translation: 'MENU.DATA_SYNC.MAIN',
    tooltip: 'MENU.DATA_SYNC.MAIN',
    authorities: ['DATA_SYNC']
};

const banners = {
    link: '/banners',
    icon: 'ti-image',
    translation: 'MENU.ADMINISTRATION.BANNERS',
    tooltip: 'MENU.ADMINISTRATION.BANNERS',
    authorities: ['R_CD']
};

const exceptionLogs = {
    link: '/exception-logs',
    icon: 'ti-agenda',
    translation: 'MENU.ADMINISTRATION.EXCEPTION_LOGS',
    tooltip: 'MENU.ADMINISTRATION.EXCEPTION_LOGS',
    authorities: ['EXC_LOG']
};

const news = {
    link: '/news',
    // icon: 'ti-image',
    translation: 'MENU.ADMINISTRATION.NEWS',
    tooltip: 'MENU.ADMINISTRATION.NEWS',
    authorities: ['R_CD']
};

const dashboard = {
    link: '/dashboard',
    icon: 'ti-dashboard',
    translation: 'MENU.DASHBOARD.DASHBOARD',
    tooltip: 'MENU.DASHBOARD.DASHBOARD'
};

const sitemap = {
    link: '/dashboard/generate-sitemap',
    icon: 'ti-dashboard',
    translation: 'SITEMAP.SITEMAP',
    tooltip: 'SITEMAP.SITEMAP'
};

const businessPartners = {
    link: '/business-partners',
    icon: 'fa-building-o',
    translation: 'MENU.DASHBOARD.BUSINESS_PARTNERS' ,
    tooltip: 'MENU.DASHBOARD.BUSINESS_PARTNERS',
    authorities: ['R_PRTNR']
};

const registrationsRequests = {
    link: '/registration-requests',
    icon: '  ',
    translation: 'MENU.DASHBOARD.REGISTRATIONS_REQUESTS',
    tooltip: 'MENU.DASHBOARD.REGISTRATIONS_REQUESTS',
    authorities: ['RA_RGST_RQST']
};


const shopDashboardConfiguration = {
    link: '/shop-dashboard-configuration',
    icon: 'ti-layout',
    translation: 'MENU.SHOP_DASHBOARD.VIEW',
    tooltip: 'MENU.SHOP_DASHBOARD.VIEW',
    authorities: ['R_DSHB_CNFG']
};

const thirdPartyApiAuth = {
    link: '/third-party-auth',
    icon: 'ti-rss',
    translation: 'MENU.THIRD-PARTY-AUTH.THIRD-PARTY-AUTH',
    tooltip: 'MENU.THIRD-PARTY-AUTH.THIRD-PARTY-AUTH',
    authorities: ['CRUD_3RD_PRTY_AUTH']
};

export interface MenuGroup {
    translation?: string;
    tooltip?: string;
    icon?: string;
    link?: string;
    authorities?: string[];
    children?: MenuGroup[];
}

export const Menu: MenuGroup[] = [
    {
        translation: 'MENU.DASHBOARD.MAIN',
        tooltip: 'MENU.DASHBOARD.MAIN',
        icon: 'ti-dashboard',
        children: [
            dashboard,
            sitemap
        ]
    },
    {
        translation: 'MENU.DATA_SYNC.MAIN',
        tooltip: 'MENU.DATA_SYNC.MAIN',
        icon: 'ti-cloud-up',
        children: [
            dataSync
        ]
    },
    {
        translation: 'MENU.DATA_SYNC.MAIN',
        tooltip: 'MENU.DATA_SYNC.MAIN',
        icon: 'ti-cloud-up',
        children: [
            dataSync
        ]
    },
    {
        translation: 'MENU.ADMINISTRATION.MAIN',
        tooltip: 'MENU.ADMINISTRATION.MAIN',
        icon: 'ti-world',
        children: [
            companies,
            businessPartners,
            users,
            customers,
            partnerUsers,
            registrationsRequests,
            roles,
            exceptionLogs,
            thirdPartyApiAuth
        ]
    },
    {
        translation: 'MENU.SETTINGS.MAIN',
        tooltip: 'MENU.SETTINGS.MAIN',
        icon: 'ti-cog',
        children: [
            paymentMethods,
            meansOfDelivery,
            defaultEmailTemplates,
            currencies,
            countries,
            municipalities,
            deliveryZones,
            taxRates,
            documentNumbering
        ]
    },
    {
        translation: 'MENU.PRODUCTS.MAIN',
        tooltip: 'MENU.PRODUCTS.MAIN',
        icon: 'ti-mouse-alt',
        children: [
            products,
            {
                translation: 'MENU.PRODUCTS.CLASSIFICATION',
                tooltip: 'MENU.PRODUCTS.CLASSIFICATION',
                icon: 'ti-mouse-alt',
                children: [
                    productAssortments,
                    productBrands,
                    productGroups,
                    productMerchandiseGroups,
                    productCategories
                ],
                authorities: ['R_CD'] //dodato zbog data sync
            },
            badges,
            templates,
            unitOfMeasurement            
        ]
    },
    {
        translation: 'MENU.ADMINISTRATION.TOS',
        tooltip: 'MENU.ADMINISTRATION.TOS',
        icon: 'ti-world',
        children: [
            pointsOfSale,
            offeredProducts,
            productCatalogues,
            rebatePolicies,
            costPolicies,
            giftPackages,
            promotionalCodeDocuments,
            distributionChannels,
            distributionCalendars
        ]
    },
    {
        translation: 'MENU.ADMINISTRATION.MARKETING',
        tooltip: 'MENU.ADMINISTRATION.MARKETING',
        icon: 'ti-pulse',
        children: [
            banners,
            news
        ]
    },
    {
        translation: 'MENU.ADMINISTRATION.CUSTOMER_ORDERS',
        tooltip: 'MENU.ADMINISTRATION.CUSTOMER_ORDERS',
        icon: 'ti-world',
        children: [
            customerOrders,
            b2bOrders
        ]
    },
    {
        translation: 'MENU.SHOP_DASHBOARD.MAIN' ,
        tooltip: 'MENU.SHOP_DASHBOARD.MAIN' ,
        icon: 'ti-layout',
        children: [
            shopDashboardConfiguration,
        ]
    }
];
