import { BaseModelActive } from '../../../shared/base/base.models';
import { Role } from '../roles/roles.models';

export class User extends BaseModelActive {
    name?: string;
    login?: string;
    password?: string;
    email?: string;
    userType?: UserType | string;
    // role?: Role | string;
    roles?: Role[] | string[];
}

export enum UserType {
    SYSTEM, CLIENT_USER, CLIENT_CUSTOMER, CLIENT_PUBLIC, DATA_SYNC, CLIENT_USER_ADMIN, PARTNER_USER_ADMIN, PARTNER_USER
}

export enum UserTypeForClientUserAdmin {
    CLIENT_USER, CLIENT_USER_ADMIN
}

export enum UserTypeOrders {
    CLIENT_CUSTOMER, CLIENT_PUBLIC
}

export enum UserTypeSystemEnum {
    SYSTEM
}

export enum UserTypePartnerEnum {
    PARTNER_USER_ADMIN, PARTNER_USER
}

export enum UserTypeNotSystemEnum {
    CLIENT_USER, CLIENT_CUSTOMER, CLIENT_USER_ADMIN, PARTNER_USER_ADMIN, PARTNER_USER
}