import { Component } from '@angular/core';

import { SettingsService } from '../../../core/settings.service';
import { TermsPolicyCookiesService } from '../../../shared/terms-policy-cookies/terms-policy-cookies.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent {
    constructor(
        public settingsService: SettingsService,
        public tpcService: TermsPolicyCookiesService
    ) { }
}
