import { Component, AfterViewInit, OnInit, HostListener, Output, EventEmitter } from '@angular/core';

import { Menu } from '../menu';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../core/auth.service';
import * as moment from 'moment';


declare let $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {
    @Output() sidebarToggled = new EventEmitter();
    public menuGroups: Array<any>;
    public scrollViewHeight: number = window.innerHeight - 100;
    today: string = moment().format('DD.MM.yyyy. HH:mm:ss');

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        if (window.innerWidth < 768)
            $('#sidebar').addClass('active');
        else
            $('#sidebar').removeClass('active');


        this.scrollViewHeight = window.innerHeight - 100;
    }

    constructor(private router: Router, private authService: AuthService) { }

    ngOnInit() {
        this.onResize();
        setInterval(() =>{
          this.today = moment().format('DD.MM.yyyy. HH:mm:ss');
        },1000)
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.generateMenu();

            $(() => {
                this.checkChildrenStates();
            });
        }, 100);

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd)
                this.checkChildrenStates();
        });
    }


    toggleSidebarCollapse() {
        $('#sidebar').toggleClass('active');
        this.sidebarToggled.emit($('#sidebar').hasClass('active'));
    }

    private generateMenu(): void {
        this.menuGroups = [];
        let menu = [...Menu];

        for (let group of menu) {
            let visibleChildren = [];

            if (group.children)
                for (let child of group.children) {
                    if (child.authorities != null && !this.authService.hasAnyAuthority(...child.authorities))
                        continue;

                    if (child.children) {
                        let vc = [];
                        for (let cc of child.children) {
                            if (cc.authorities == null || this.authService.hasAnyAuthority(...cc.authorities))
                                vc.push(cc);
                        }

                        child.children = vc;
                    }
                    visibleChildren.push(child);
                }

            if (visibleChildren.length > 0)
                this.menuGroups.push({
                    link: visibleChildren.length > 1 ? '' : visibleChildren[0].link,
                    icon: visibleChildren[0].icon,
                    translation: visibleChildren.length > 0 ? group.translation : visibleChildren[0].translation,
                    tooltip: visibleChildren.length > 1 ? group.tooltip : visibleChildren[0].tooltip,
                    children: visibleChildren
                });
            else
                if (group.authorities == null || this.authService.hasAnyAuthority(...group.authorities))
                    this.menuGroups.push({
                        link: group.link,
                        icon: group.icon,
                        translation: group.translation,
                        tooltip: group.tooltip
                    });
        }
    }

    private checkChildrenStates(): void {
        if (this.menuGroups != null)
            for (let i = 0; i < this.menuGroups.length; i++)
                if (this.menuGroups[i].children && this.menuGroups[i].children.find((e: any) => this.router.isActive(e.link, true))) {

                }
    }

}
