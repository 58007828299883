import { Component } from '@angular/core';
import { LoadingService } from 'src/app/core/loading.service';
import { AuthService } from 'src/app/core/auth.service';
import { AlertService } from 'src/app/core/alert.service';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/shared/base/base.service';
import { Constants } from 'src/app/app.constants';
import { inOutAnimation } from '../../animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [ inOutAnimation ]
})
export class LoginComponent {
  showForgotPassword = false;
  public formData = {
    username: '',
    password: ''
  };

  changePasswordFormData: { email: string } = { email: null };


  // public logo = window.location.hostname;

  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
    private alertService: AlertService,
    public router: Router,
    protected baseService: BaseService
  ) { }


  public login(): void {
    this.loadingService.showLoader();
    
    this.authService.login(this.formData.username, this.formData.password).subscribe(
      () => {
        this.loadingService.hideLoader();
        if(!this.authService.hasRole('CLIENT_CUSTOMER')){
          this.authService.performRedirection()
        }else{
          this.authService.removeAuth();
          this.alertService.error('ERRORS.LOGIN_FAILED');
        }
      },
      (error) => {
        this.loadingService.hideLoader();
        if (error.error.error === 'unauthorized')
          this.alertService.error('ERRORS.USER_DOES_NOT_EXIST');
        else if (error.error.error === 'invalid_grant')
          this.alertService.error('ERRORS.WRONG_PASS_OR_EMAIL');
        else
          this.alertService.error(error, true);
      }
    );
  }

  showForgotPasswordForm(){
    this.showForgotPassword = !this.showForgotPassword;
  }

  changePassword(){
    if(this.changePasswordFormData.email != null){
    this.baseService.patch(`${Constants.contextPath}/users/forgot-password/${this.changePasswordFormData.email}/ADMIN`, null, (res)=> {
      this.alertService.success('REGISTRATIONS.RESET_PW', 10000);
    }, ()=> {
      this.alertService.error('ERRORS.GENERAL', false, 5000);
    });
    }
  }
}
