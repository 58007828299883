import { Injectable } from '@angular/core';
import { BaseService } from '../../../shared/base/base.service';
import { Constants } from '../../../app.constants';
import { RequestService } from 'src/app/core/request.service';
import { AlertService } from 'src/app/core/alert.service';
import { Company, CompanyConfiguration, CompanyConfigurationKey } from './companies.models';
import { AuthService } from 'src/app/core/auth.service';
import { UserType } from '../users/users.models';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends BaseService {
  url = `${Constants.contextPath}/companies`;
  url_al = '/accessLeveel';
  company: Company;

  constructor(protected requestService: RequestService, private alertService: AlertService, private authService: AuthService) {
    super(requestService);
  }

  getCompaniesOptions() {
    return this.getOptions(this.url, 'name');
  }

  setAccessLevelForCompany(companyId: string, accessLevel: number, onSuccess: Function = null){
    this.patch(this.url + `/${companyId}${this.url_al}/${accessLevel}`, null,
      (respone) => {
        if(onSuccess)
          onSuccess(respone)
        this.alertService.success('SHARED.SAVE_SUCCESS');
    }, (err) => {
      this.alertService.error(err, true);
    })
  }

  setAccessLevelForCompanies(accessLevel: number, onSuccess: Function = null){
    this.patch(this.url + `${this.url_al}/${accessLevel}`, null,
      (respone) => {
        if(onSuccess)
          onSuccess(respone)
        this.alertService.success('SHARED.SAVE_SUCCESS');
    }, (err) => {
      this.alertService.error(err, true);
    })
  }

  getByConfigurationKey(key: string): Promise<CompanyConfiguration> {
    return new Promise(resolve => {
      this.getAll(`${this.url}/get-by-key/${key}`, res => resolve(res));
    })
  }

  getCompany(id: string): Promise<Company> {
    return new Promise(resolve => {
      if (this.company?.id == id || this.authService?.userType == UserType[UserType.SYSTEM])
        resolve(this.company);
      else
        this.getById(`${this.url}`, id, res => {
          this.company = res;
          resolve(res);
        });
    })
  }
}
