
import { trigger, style, animate, transition } from '@angular/animations';

export const inOutAnimation = trigger(
    'inOutAnimation',
    [
        transition(
            ':enter',
            [
                style({ transform: 'translateY(15px)', opacity: 0 }),
                animate('.2s ease-out',
                    style({ transform: 'translateY(0)', opacity: 1 }))
            ]
        ),
        transition(
            ':leave',
            [
                style({ transform: 'translateY(0)', opacity: 1 }),
                animate('0s ease-in',
                    style({ transform: 'translateY(15px)', opacity: 0 }))
            ]
        )
    ]
)