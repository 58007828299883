<div class="main-container">
    <ul class="bg-bubbles">
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
	</ul>
    <div class="form-container" [ngClass]="{ 'full-width': router.url.toString().startsWith('/regist') }">
        <!-- <div class="text-center">
            <span class="ls url-like" (click)="translatorService.useLanguage('bs-BA')">Bosanski</span>
            <span class="ls url-like" (click)="translatorService.useLanguage('hr-HR')">Hrvatski</span>
            <span class="ls url-like" (click)="translatorService.useLanguage('sr-RS')">Српски</span>
            <span class="ls url-like" (click)="translatorService.useLanguage('en-US')">English</span>
        </div> -->
        <!-- <img class="logo pointer" src="assets/logo.png" alt="" (click)="router.navigate(['login'])" /> -->
        <router-outlet></router-outlet>
    </div>
    <!-- <div class="footer drop-shadow" app-footer></div> -->
</div>
