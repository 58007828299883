import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { AuthService } from '../../../core/auth.service';
import { NotificationType } from '../../../shared/notifications/notifications.models';
import { CompanyConfigurationKey } from '../../routes/companies/companies.models';
import { CompaniesService } from '../../routes/companies/companies.service';
import { ProfileService } from '../../routes/profile/profile.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public logo: string;
    public defaultNotification = NotificationType[NotificationType.SYSTEM].toLowerCase();

    constructor(public authService: AuthService, public router: Router, public notificationsService: NotificationsService, private companiesService: CompaniesService, private profileService: ProfileService) { }

    ngOnInit(): void {
        if (this.authService.userType != 'SYSTEM')
            this.profileService.getLogo().then(() => this.logo = this.profileService.logo);
        else
            this.logo = '../../../../assets/eurobitLogoOnBlack.png'
        if (this.authService.hasAuthority('R_NTF'))
            this.notificationsService.checkUnreadNotifications();
    }

    public handleSidebarToggle(): void {
        $('body').toggleClass('is-collapsed');
    }
}
