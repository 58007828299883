import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../app.constants';
import { RequestService, HttpRequestType } from '../../core/request.service';
import { AlertService } from '../../core/alert.service';
import { AuthService } from 'src/app/core/auth.service';

@Component({
    template: `<div (click)="redirect()">{{ 'MENU.DASHBOARD.MAIN' | translate}}</div>`
})
export class EmailChangeConfirmationComponent implements OnInit {
    private token: string = window.location.pathname.split('/')[2];
    private url: string = `${ Constants.contextPath }/users/change-email/confirm-email-change/${ this.token }`;

    constructor(private authService: AuthService, public router: Router, private requestService: RequestService, private alertService: AlertService) { }

    ngOnInit(): void {
        if(this.authService.isTokenSet || this.authService.isTokenInStorage) {
            this.authService.removeAuth();
        }
        this.confirmEmailChangeAndRedirect();
    }

    public confirmEmailChangeAndRedirect() {
        this.requestService.createRequest(HttpRequestType.Patch, this.url, null, null, () => {
            this.alertService.success('EMAIL_CHANGE.CONFIRM_SUCCESS');
            this.redirect();
        },
        (error: any) => {
            this.alertService.error(error, true);
            this.redirect();
        });
    }

    public redirect() {
        this.router.navigate(['login']);
    }
}
