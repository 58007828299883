import { BaseModelActive, BaseModel } from '../../../shared/base/base.models';
import { Country } from '../countries/countries.models';

export class Company extends BaseModelActive { 
    accessLevel?: string;
    code?: string;
    name?: string;
    country?: string | Country;
    place?: string;
    address?: string;
    zipCode?: number;
    telephone?: string;
    email?: string;
    web?: string;
    vatPayer?: boolean;
    vatNumber?: string;
    idNumber?: string;
    registrationNumber?: string;
    geoX?: number;
    geoY?: number;
    companyType?: string | CompanyType;
    calculationType?: string | CalculationType;
    configuration?: CompanyConfiguration[] = [];
    emailTemplate?: CompanyEmailTemplate[] = [];
}

export class CompanyEmailTemplate extends BaseModel{
    key: string | EmailTemplateKey;
    subject?: string;
    body?: string;
    attachmentLink?: string;
    companyName?: string;
    description?: string;
}

export class CompanyConfiguration {
    key: string | CompanyConfigurationKey;
    value: string;
}

export enum CompanyConfigurationKey {
    // PASS
    PASS_VALIDATOR, PASS_VALIDITY_PERIOD, PASS_LENGTH_ADMIN, PASS_LENGTH_USER, PASS_CONFIRMATION_LINK, PASS_PENDING_PASSWORD_PERIOD,
    
    
    // SMTP
    SMTP_HOST, SMTP_PORT, SMTP_USERNAME, SMTP_PASSWORD, SMTP_SSL, SMTP_FROM_NAME, SMTP_FROM_EMAIL,

    // HTTP
    HTTP_PROTOCOL, HTTP_BASE_FRONT_URL, HTTP_BASE_FRONT_URL_B2B, HTTP_BASE_FRONT_URL_ADMIN,
    //
    // PUBLIC_USER_ID, // izbaceno jer se ne koristi na backendu
    //
    STOCK_QUANTITY_UPDATE, PRODUCT_INSERT, OFFERED_PRODUCT_SPECIAL_TREATMENT,

    //SHOP DISPLAY DELIVERY TIME
    DELIVERY_SHOW_TODAY, DELIVERY_FIRST_TIMESLOT_HOURS_STEP, DELIVERY_SHOW_DAYS,

    //GENERATING DISTRIBUTION CALENDARS CONFIGURATION
    GENERATING_CALENDARS_HOURS_STEP,

    //OPA
    OPA_URL, REQUEST_QR_PAYMENT_DURATION,

    //UPC - PAYMENT
    UPC_MERCHANT_ID, UPC_TERMINAL_ID, UPC_CURRENCY_NUMBER, UPC_LOCALE_CODE, UPC_SUCCESS_URL, UPC_FAIL_URL, UPC_PREAUTHORIZATION, UPC_PREAUTHORIZATION_URL,

    // IMAGES
    ICON_LOGO_URL
}

export enum CompanyType {
    WEB, B2B, B2C, ALL
}

export enum CalculationType {
    SMALL, MEDIUM, LARGE, ENTERPRISE
}

export enum EmailTemplateKey {
    USER_UPDATED, USER_REQUEST_PASSWORD_CHANGE, USER_PASSWORD_CHANGED, USER_DEACTIVATED, USER_ACTIVATED, USER_REQUEST_PASSWORD_FORGOT, USER_REQUEST_EMAIL_CHANGE,

    // Registration
    CONFIRM_REGISTRATION, REGISTRATION_SUCCESS,

    // Customer order
    CUSTOMER_ORDER_SUBMITTED, CUSTOMER_ORDER_APPROVED, CUSTOMER_PROMO_CODE, CUSTOMER_ORDER_CANCELED, CUSTOMER_ORDER_DELIVERED, CUSTOMER_ORDER_PARTIALY_DELIVERED,

    // Request registration
    REQUEST_REGISTRATION_APPROVED, REQUEST_REGISTRATION_DECLINED,

    // New user business partner created
    NEW_PARTNER_REGISTERED, USER_CREATED,

    // B2C Orders
    B2B_ORDER_SUBMITTED, B2B_ORDER_FINALIZED, B2B_ORDER_WITHDRAWN, B2B_ORDER_DELIVERED, B2B_ORDER_PARTIALLY_DELIVERED, B2B_ORDER_CANCELED,

    B2B_CATALOGUE

}

export enum AccessLevel {
    CLIENT_PUBLIC, CLIENT_CUSTOMER, CLIENT_USER_AND_PARTNER_USER_ADMIN, CLIENT_USER_ADMIN, DATA_SYNC, SYSTEM    
}