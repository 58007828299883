import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../shared/material.module';
import { TranslatorService } from './translator.service';

@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        MaterialModule
    ]
})
export class CoreModule {
    constructor(private translatorService: TranslatorService) { }
}
