import { Injectable } from '@angular/core';
import { AppClientType } from 'src/app/shared/base/base.models';
import { Constants } from '../../../app.constants';
import { AuthService } from '../../../core/auth.service';
import { HttpRequestType, RequestService } from '../../../core/request.service';
import { BaseService } from '../../../shared/base/base.service';
import { CompanyConfiguration, CompanyConfigurationKey } from '../companies/companies.models';

@Injectable({
  providedIn: 'root'
})

export class ProfileService extends BaseService {
  logo: string;

  constructor(protected requestService: RequestService, private authService: AuthService) {
    super(requestService)
  }

  public getCountriesOptions() {
    return this.getOptionsForOutsideRoute(`${Constants.contextPath}/countries`);
  }

  public changePassword(body: any, onSuccess: Function, onFail: Function) {
    this.requestService.createRequest(HttpRequestType.Patch, `${Constants.contextPath}/users/changepassword`, body, null, (response: any) => {
      onSuccess(response);
    }, (error: any) => {
      onFail(error);
    });
  }

  public changeEmail(email: string, id: string, onSuccess: Function, onFail: Function) {
    let url = `${Constants.contextPath}/users/change-email/${email}/${AppClientType[AppClientType.ADMIN]}`;
    this.requestService.createRequest(HttpRequestType.Patch, url, null, null, (response: any) => {
      onSuccess(response);
    }, (error: any) => {
      onFail(error);
    });
  }
  
  public getByConfigurationKey(key: string): Promise<CompanyConfiguration> {
    return new Promise(resolve => {
      this.getAll(`${Constants.contextPath}/companies/get-by-key/${key}`, res => resolve(res));
    })
  }

  public async getLogo() {
    this.logo = (await this.getByConfigurationKey(CompanyConfigurationKey[CompanyConfigurationKey.ICON_LOGO_URL])).value;
  }
}
