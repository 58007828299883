<p class="fixed-top">
    <mat-toolbar class="header-nav">
        <div class="sidebar-header">
            <dx-load-indicator [visible]="!logo" [height]="30" [width]="30"></dx-load-indicator>
            <img *ngIf="logo" [src]="logo" class="img-fluid height-35p d-block m-auto clickable outline-none" [routerLink]="['/']">
        </div>
        <span class="example-spacer"></span>
        <mat-icon [matTooltip]="'MENU.RIGHT_HAND_MENU.NOTIFICATIONS' | translate" matTooltipPosition="below" [matBadge]="notificationsService.unreadNotificationsNumber" matBadgeColor="accent" matBadgeSize="small" color="primary" [routerLink]="['/dashboard/notifications/'+defaultNotification]" *ngIf="notificationsService.unreadNotificationsNumber > 0 && authService.hasAnyAuthority('R_NTF')" class="clickable outline-none animate__animated animate__wobble animate__infinite infinite animate__delay-1s animate__slow">email</mat-icon>
        <mat-icon [matTooltip]="'MENU.RIGHT_HAND_MENU.NOTIFICATIONS' | translate" matTooltipPosition="below" color="primary" [routerLink]="['/dashboard/notifications/'+defaultNotification]" *ngIf="notificationsService.unreadNotificationsNumber === 0 && authService.hasAnyAuthority('R_NTF')" class="clickable outline-none">email</mat-icon>

        <button mat-button color="secondary" [matMenuTriggerFor]="beforeMenu">
            {{ authService.username }} <span class="material-icons menu-icon">keyboard_arrow_down</span>            
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
            <button mat-menu-item [routerLink]="['/profile']"><mat-icon class="menu-item-icon fs-20">account_box</mat-icon> <span>{{ 'MENU.RIGHT_HAND_MENU.ME' | translate }}</span></button>
            <!-- <button mat-menu-item *ngIf="authService.hasAnyAuthority('R_NTF')" [routerLink]="['/dashboard/notifications/'+defaultNotification]"><mat-icon class="menu-item-icon fs-20">notifications</mat-icon> <span>{{ 'SHARED.NOTIFICATIONS' | translate }}</span></button> -->
            <button mat-menu-item [routerLink]="['/logout']" routerLinkActive="router-link-active" ><mat-icon class="menu-item-icon fs-20">exit_to_app</mat-icon> <span>{{ 'SHARED.LOG_OUT' | translate }}</span></button>
        </mat-menu>
    </mat-toolbar>
</p>